import { render, staticRenderFns } from "./OrganismMenuBarProject.vue?vue&type=template&id=4487aef2&"
import script from "./OrganismMenuBarProject.vue?vue&type=script&lang=js&"
export * from "./OrganismMenuBarProject.vue?vue&type=script&lang=js&"
import style0 from "./OrganismMenuBarProject.vue?vue&type=style&index=0&id=4487aef2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismMenuBarProject.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2FOrganismMenuBarProject.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,AtomsCommonAtomLogo: require('/app/components/atoms/common/AtomLogo.vue').default,AtomsCommonAtomDropdown: require('/app/components/atoms/common/AtomDropdown.vue').default,AtomsCommonAtomButtonLink: require('/app/components/atoms/common/AtomButtonLink.vue').default,AtomsCommonAtomLocaleSwitch: require('/app/components/atoms/common/AtomLocaleSwitch.vue').default,MoleculesMoleculeMenuTrigger: require('/app/components/molecules/MoleculeMenuTrigger.vue').default})
